import {mapMutations} from "vuex";

//sections
import mainSection from '@/modules/home/section/main/index.vue';
import bricksSection from '@/modules/home/section/bricks/index.vue'
import heroesSection from '@/modules/home/section/heroes/index.vue'
import flowSection from '@/modules/home/section/flow/index.vue'
import blocksSection from '@/modules/home/section/blocks/index.vue'
import videoSection from "@/modules/home/section/video/index.vue"
import photoSection from '@/modules/home/section/photos/index.vue'
export default {
  name: "home",
  components:{
    mainSection,
    bricksSection,
    heroesSection,
    flowSection,
    blocksSection,
    videoSection,
    photoSection
  },
  data(){
    return{

    }
  },
  created() {
    let code = localStorage.getItem('hero_code');
    if (code) {
      this.showFirstHeroPopup(true);
    }
  },
  computed:{

  },

  methods:{
    ...mapMutations({
      showFirstHeroPopup: 'popups/SHOW_FIRST_PERSONAGE'
    })
  }
}
