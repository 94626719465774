import {mapMutations} from "vuex";

export default {
    name: "heroes",
    components: {},
    data() {
        return {}
    },
    created() {
    },
    computed: {},
    methods: {
        ...mapMutations({}),
        toggleMenu() {
            this.$refs.burger.classList.toggle('open')

        }
    }
}
