import {mapMutations} from "vuex";

export default {
    name: "flow",
    components: {},
    data() {
        return {}
    },
    created() {

    },
    computed: {},
    methods: {
        ...mapMutations({
            showMobilePopups: 'popups/SHOW_GAME_ON_MOBILE',
            showAuthPopup: 'popups/SHOW_LOGIN'
        }),
        redirectToGame() {
            let isAuth = localStorage.getItem('user_token')
            let userAgent = navigator.userAgent || navigator.vendor || window.opera;

            if (/windows phone/i.test(userAgent) || /android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                if(isAuth){
                    this.$router.push({name: 'qr'})
                }else{
                    this.showAuthPopup(true)
                }

            }else{
                this.showMobilePopups(true)
            }
        },
        openPopup() {
            this.showAuthPopup(true)
            // this.$router.push('qr')
            // this.showMobilePopups(true)

        }
    }
}
