import {mapMutations} from "vuex";

export default {
    name: "main",
    components: {},
    data() {
        return {}
    },
    created() {
    },
    computed: {},
    methods: {
        ...mapMutations({}),
        toggleMenu() {
            this.$refs.burger.classList.toggle('open')
             this.$refs.menuOpen.classList.toggle('menu-open')
        },
        smoothScroll(el) {
            const myEl = document.getElementById(el)
            this.$smoothScroll({
                scrollTo: myEl,
            })
        },
    }
}
